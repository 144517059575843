import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { DCIGaugeSimple } from "./dci-gauge-simple";

export const Meta: DocPageMeta = {
	category: "Data Visualization",
	title: "DCI Gauge Simple",
	notepad: "https://hackmd.io/TCiFhXPGSKCCbwhdmy1CiQ",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Polar Gauge Simple (DCI Gauge Simple)"
			subTitle="Visually communicates a numerical value as a percentage of a target (100) within a circular format."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={DCIGaugeSimple} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The Polar Gauge Simple component is a minimalist data visualization tool designed to
					clearly and efficiently communicate a single metric:
				</Paragraph>
				<Paragraph>The component consists of:</Paragraph>
				<Ul
					items={[
						<>
							<b>Score</b>: Displays the current score (e.g., "25.9").
						</>,
						<>
							<b>Progress Indicator</b>: Visually represents the score as a filled portion of the
							circle.
						</>,
					]}
				/>
				<Header.H3>Basic Usage, Small Size</Header.H3>
				<Paragraph>
					Ideal for compact displays like table cells or within card or table components where space
					is limited.
				</Paragraph>
				<Example fn={SmallSize} />
				<Header.H3>Basic Usage, Medium Size</Header.H3>
				<Paragraph>
					Suitable for more prominent display on dashboards, reports, or when the visual impact of
					the gauge is important.
				</Paragraph>
				<Example fn={MediumSize} />
				<Header.H2>Properties</Header.H2>
				<Knobs component={DCIGaugeSimple} initialProps={{ score: 33, size: "small" }} />
				<Header.H2>Guidelines</Header.H2>
				<InlineMessage variant="best-practices">
					<Header.H3>Best practices</Header.H3>
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>DCIGaugeSimple</Code> when
					</Paragraph>
					<Ul
						items={[
							"Space is limited, or a quick visual assessment is needed",
							"A visual representation of progress or a target value is more impactful than just a number.",
							"The target value (100) is understood by the user, either implicitly or through supporting context.",
						]}
					/>

					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>DCIGaugeSimple</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								Small Size:
								<Ul
									items={[
										<>
											{" "}
											<GatsbyLink to="/lab/components/Tables%20and%20lists/Table">Table</GatsbyLink>
											: To quickly compare multiple metrics.
										</>,
										<>
											<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>: To provide
											an overview of a specific KPI within a card.
										</>,
										<>
											<GatsbyLink to="/lab/components/Text/Highlight">Highlight</GatsbyLink>: To
											offer a compact summary of progress.
										</>,
									]}
								/>
							</>,
							<>
								Medium Size:
								<Ul
									items={[
										<>Dashboard Widgets: For a prominent display of key metrics.</>,
										<>Report Summaries: To visually emphasize a particular data point.</>,
									]}
								/>
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>DCIGaugeSimple</Code> to existing components for visual
								consistency.
							</>,
							<>
								<b>Meaningful data</b>: Ensure the displayed score or progress value is relevant and
								actionable for the user.
							</>,
							<>
								<b>Prioritize clarity</b>: The component should be instantly understandable. Avoid
								clutter and extraneous elements.
							</>,
							<>
								Use color to reinforce the meaning of the data (e.g., green for positive, red for
								negative).
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								<b>The data is highly volatile</b>: A gauge isn't suitable for rapidly changing
								values. Use{" "}
								<GatsbyLink to="/lab/components/Data Visualization/Chart">Chart</GatsbyLink> or
								other data visualisation.
							</>,
							<>
								<b>A trend over time is more important</b>: Consider a Line{" "}
								<GatsbyLink to="/lab/components/Data Visualization/Chart">Chart</GatsbyLink> or{" "}
								<GatsbyLink to="/lab/components/Data Visualization/Spark Line">
									SparkLine
								</GatsbyLink>{" "}
								for trends.
							</>,
							<>
								<b>Precise numerical values are critical</b>: A gauge is an approximation; use a
								numerical display if exact figures are necessary. Use{" "}
								<GatsbyLink to="/lab/components/Text/Highlight">Highlight</GatsbyLink> component
								instead.
							</>,
							<>
								<b>The metric does not have a target of 100</b>: A gauge requires a defined goal to
								measure against.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Provide sufficient color contrast between the gauge, indicator, and background.",
							<>
								Provide text alternatives for screen readers, describing the score and its meaning
								in context (e.g., "DCI score: 35 out of 100").
							</>,
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Use clear, concise labels for the gauge label or any accompanying text.",
							<>
								Explain the meaning of the score in context (e.g., "Your website's DCI score is 35,
								indicating room for improvement").
							</>,
							<>If applicable, offer guidance on how to interpret or improve the score.</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const SmallSize = () => <DCIGaugeSimple score={33} size="small" />;
const MediumSize = () => <DCIGaugeSimple score={33} size="medium" />;
