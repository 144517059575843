import React from "react";
import { cn, DataObserveKey, VisualComponent } from "@siteimprove/fancylib";
import { colorFromPercentageRedToGreenScale } from "../../../utils/color-utils";
import { FormattedNumber } from "../../text/formatted-number/formatted-number";
import * as scss from "./dci-gauge-simple.scss";

export type DciGaugeSimpleProps = {
	/** DCI score between 0-100 */
	score: number | null;
	/** Controls the size of the DCI - defaults to "small" */
	size?: "small" | "medium";
	/** Shows question mark when score is zero or null*/
	useQuestionMark?: boolean;
	"data-observe-key"?: string;
} & DataObserveKey &
	VisualComponent;

export function DCIGaugeSimple(props: DciGaugeSimpleProps): JSX.Element {
	const { style, className, score, size = "small", useQuestionMark = true } = props;

	const defaultCircleSize = 32;
	const circleSize = size === "small" ? defaultCircleSize : 64;
	// If other size is provided, formula for fontSize is 12 * (circleSize / defaultCircleSize)
	const fontSize = size === "small" ? 12 : 24;

	let scoreDisplay;
	if (score) {
		const roundedScore = Math.round(score * 10) / 10;
		scoreDisplay = (
			<FormattedNumber
				number={roundedScore}
				digits={roundedScore === 100 ? 0 : 1}
			></FormattedNumber>
		);
	} else if (!useQuestionMark) {
		scoreDisplay = <FormattedNumber number={0} digits={0}></FormattedNumber>;
	} else {
		scoreDisplay = "?";
	}

	// The following is to create the progress line in the offset of the circle
	// lineThickness will be the size of the progress bar inside the main circle
	const lineThickness = 10;
	// radius of the main circle
	const radius = 45;
	// circumference will define the strokeDashArray of the SVG, basically the perimeter of the offset
	// in this case, it'll be the size of a circumference (2*pi*radius)
	const circumference = +scss.circumference;
	// the following formula just defines how much of the offset should be filled (to fill only the score)
	// for example, say circumference is 200, score is 50, offset will be 100, filling half of the perimeter of the circle
	const offset = Math.round(circumference * (1 - (score || 0) / 100) * 100) / 100;

	const circleProps = {
		cx: 50,
		cy: 50,
		r: radius,
		fill: "none",
		strokeWidth: lineThickness,
	};

	return (
		<div
			className={cn(scss.scoreGauge, className)}
			style={{ ...style, width: circleSize, height: circleSize }}
			data-component="dci-gauge-simple"
			data-observe-key={props["data-observe-key"]}
		>
			<svg
				viewBox="0 0 100 100"
				className={cn(scss.svgGauge)}
				width={circleSize}
				height={circleSize}
				focusable={false}
				aria-hidden="true"
			>
				<circle className={cn(scss.bgCircle)} {...circleProps} />
				{score && (
					<circle
						className={cn(scss.progressCircle)}
						{...circleProps}
						stroke={colorFromPercentageRedToGreenScale(score)}
						strokeDasharray={circumference}
						strokeDashoffset={offset}
					/>
				)}
			</svg>
			<div className={cn(scss.number)} style={{ fontSize: fontSize }}>
				{scoreDisplay}
			</div>
		</div>
	);
}
